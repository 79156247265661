import { ZodError, ZodSchema } from "zod";

type ValidationResult<T> = { success: true; data: T } | { success: false; error: ZodError };

export const validateDataWithZodSchema = <T>(schema: ZodSchema<T>, data: any): ValidationResult<T> => {
  try {
    const validatedData = schema.parse(data);
    return { success: true, data: validatedData };
  } catch (error) {
    if (error instanceof ZodError) {
      return { success: false, error };
    }
    // Re-throw the error if it's not a ZodError.
    throw error;
  }
};
